import type {ComponentType} from 'react'
import React from 'react'
import {palette} from '@ambler/andive'
import type {Doctor, HospitalType, MedicalTransporterType} from '@ambler/shared'
import hoistNonReactStatics from 'hoist-non-react-statics'
import type {RedirectLoginOptions} from '@auth0/auth0-react'
import {Auth0Provider, useAuth0} from '@auth0/auth0-react'
import {isIn} from '@ambler/shared'

import {useRouter} from 'next/router'
import gql from 'graphql-tag'
import {flowRight as compose} from 'lodash'
import {Andiv, Text} from '@ambler/andive-next'
import {Browser} from '@capacitor/browser'
import LogoAppBar from '../logo-app-bar'
import {Title} from '../title'
import capacitorSecureStorage from '../../lib/mobile/secure-storage'

import {useMutation} from '../../hooks/use-mutation'
import {getUpdateDevicePayload, useForegroundNotifications, useMobileNotifications} from '../../lib/firebase-messaging'
import {getBundleId} from '../../lib/mobile/bundle-id'
import {withNextApolloClient} from '../../lib/with-apollo'
import {withFavicon} from '../favicon'
import {LoaderCard} from '../loader'
import useAppUrlListeners from '../mobile/app-url-listeners'
import {useSplashAutoHideFallback} from '../../lib/mobile/splash-screen'
import {AppContainer} from './responsive'
import type {APP_logoutDeviceMutationVariables, APP_logoutDeviceMutation_} from './auth.generated'
import {useProfile, withProfile} from './profile'
import {withSentryUser} from './sentry'
import {withConnectAsOverlay} from './connect-as-overlay'
import type {APP_profileQuery_} from './profile.generated'
import {withMultiMf} from './multi-mf'

type SignInOptions = {
  callbackUrl?: string
  signUp?: boolean
  hint?: string
}

type MedicalTransporter = {
  id: string
  name?: string
  type?: MedicalTransporterType
}

type MedicalFacilityUnit = {
  id: string
  name?: string
  hospitalType?: HospitalType
  needPayerDisplay?: boolean
  needAmbulance?: boolean
  needBariatric?: boolean
  needMedical?: boolean
  needParamedical?: boolean
  needTpmr?: boolean
  needVsl?: boolean
}

export type MtAcl = {
  canRead: boolean
  canWrite: boolean
  mt: MedicalTransporter
}

export type MfuAcl = {
  canList: boolean
  canOrder: boolean
  canSignFor: Pick<Doctor, 'id'>[]
  mfu: MedicalFacilityUnit
}

export const auth0Params = {
  domain: process.env.AUTH0_ENDPOINT,
  client_id: process.env.AUTH0_CLIENT_ID,
  redirect_uri: (() => {
    if (process.env.IS_MOBILE_BUILD) {
      return `${getBundleId()}://${process.env.AUTH0_ENDPOINT}/capacitor/${getBundleId()}/callback`
    }
    return process.env.AMBLER_APP_URL
  })(),
  scope: 'openid email profile offline_access',
  cacheLocation: process.env.IS_MOBILE_BUILD ? undefined : ('localstorage' as any),
  cache: process.env.IS_MOBILE_BUILD ? capacitorSecureStorage : undefined,
  useRefreshTokens: true,
}

const withAuth0Provider = (Component: ComponentType) => {
  function WithAuth0Provider(props: any) {
    return (
      <Auth0Provider
        domain={auth0Params.domain}
        clientId={auth0Params.client_id}
        redirectUri={auth0Params.redirect_uri}
        scope={auth0Params.scope}
        cacheLocation={auth0Params.cacheLocation}
        cache={auth0Params.cache}
        useRefreshTokens={auth0Params.useRefreshTokens}
      >
        <Component {...props} />
      </Auth0Provider>
    )
  }
  hoistNonReactStatics(WithAuth0Provider, Component)
  return WithAuth0Provider
}

const logoutDeviceMutation = gql`
  mutation APP_logoutDeviceMutation($data: PushNotificationsUpsertDeviceDataInput!) {
    pushNotificationsUpsertDevice(data: $data)
  }
`

// Legacy hooks.

export const toOldMtAcl = (mtProfile: APP_profileQuery_['profile']['mts'][number]): MtAcl => {
  if (!mtProfile) {
    return undefined
  }

  const {mt, acl} = mtProfile

  return {
    canRead: acl.canRead,
    canWrite: acl.canWrite,
    mt: {
      id: mt.id,
      name: mt.name,
      type: mt.type,
    },
  }
}

export const useMainMt = (): MtAcl => {
  const profile = useProfile()
  return toOldMtAcl(profile.mts[0])
}

//************* */
// AUTH
//************* */

type AuthContextType = {
  userId: string
  isAuthenticated: boolean
  isLoading: boolean
  getAccessTokenSilently: () => Promise<string>
  signIn: (arg0?: SignInOptions) => Promise<void>
  signOut: () => Promise<void>
}

const AuthContext = React.createContext<AuthContextType>({
  userId: null,
  isAuthenticated: false,
  isLoading: true,
  getAccessTokenSilently: null,
  signIn: null,
  signOut: null,
})

const useMobileFeatures = () => {
  // ? mobile app splash screen hides automatically in case hideSplashScreen is not called after a certain amount of time
  useSplashAutoHideFallback()
  // ? mobile app auth0callbacks and deeplinks
  useAppUrlListeners()
  useMobileNotifications()
  useForegroundNotifications()
}

const withAuthProvider = (Component: ComponentType) => {
  function WithAuthProvider(props: any) {
    useMobileFeatures()

    const [logoutDevice] = useMutation<APP_logoutDeviceMutation_, APP_logoutDeviceMutationVariables>({
      mutation: logoutDeviceMutation,
    })
    const {
      user,
      isAuthenticated,
      isLoading,
      loginWithRedirect,
      logout,
      buildAuthorizeUrl,
      buildLogoutUrl,
      getAccessTokenSilently,
    } = useAuth0()

    const signIn = React.useCallback(
      async (options: SignInOptions = {}) => {
        const {signUp, hint, callbackUrl} = options
        const redirectUri = `${auth0Params.redirect_uri}/authcallback`
        const params: RedirectLoginOptions = {
          redirectUri: callbackUrl ?? redirectUri,
          login_hint: hint,
          screen_hint: signUp && 'signup', // https://auth0.com/docs/authenticate/login/auth0-universal-login/new-experience#signup
        }
        // ! mobile
        if (process.env.IS_MOBILE_BUILD) {
          const url = await buildAuthorizeUrl(params)
          await Browser.open({url})
        } else {
          // ! webapp
          loginWithRedirect(params)
        }
      },
      [buildAuthorizeUrl, loginWithRedirect],
    )

    const signOut = React.useCallback(async () => {
      try {
        await logoutDevice({
          variables: {
            data: {
              ...(await getUpdateDevicePayload()),
              state: 'LOGGED_OUT',
              fcmToken: null,
            },
          },
        })
      } catch (_err) {
        // no op but we want the logout to happen
      }
      // ! mobile
      if (process.env.IS_MOBILE_BUILD) {
        const url = buildLogoutUrl({returnTo: auth0Params.redirect_uri})
        await Browser.open({url})
        logout({localOnly: true})
      } else {
        // ! webapp
        logout({
          returnTo: `${auth0Params.redirect_uri}/log-in`,
        })
      }
    }, [buildLogoutUrl, logout, logoutDevice])

    const value = React.useMemo<AuthContextType>(
      () => ({
        userId: user?.['https://api.ambler.fr/']?.id,
        isAuthenticated,
        isLoading,

        signIn,
        signOut,
        getAccessTokenSilently: () =>
          getAccessTokenSilently(process.env.IS_MOBILE_BUILD ? {redirect_uri: window.location.href} : undefined),
      }),
      [getAccessTokenSilently, isAuthenticated, isLoading, signIn, signOut, user],
    )

    return (
      <AuthContext.Provider value={value}>
        <Component {...props} />
      </AuthContext.Provider>
    )
  }
  hoistNonReactStatics(WithAuthProvider, Component)
  return WithAuthProvider
}

export const useAuth = () => React.useContext(AuthContext)

export const LoadingPage = ({message}: {message?: string}) => {
  return (
    <>
      <LogoAppBar withLogin={false} />
      <AppContainer>
        <LoaderCard message={message} />
      </AppContainer>
    </>
  )
}

const NotAuthorizedPage = ({debug}: {debug?: string}) => {
  return (
    <>
      <LogoAppBar />
      <AppContainer>
        <Andiv column p="16px">
          <Title>Accès refusé</Title>
          <Text>
            Vous n'avez pas accès à cette page. Si vous pensez qu'il s'agit d'une erreur, n'hésitez pas à nous contacter
            : <a href="mailto:contact@ambler.fr">contact@amblea.fr</a>
          </Text>
          {Boolean(debug && process.env.NODE_ENV === 'development') && (
            <Text t="body2" color={palette.amblea.grey[600]}>
              {debug}
            </Text>
          )}
        </Andiv>
      </AppContainer>
    </>
  )
}

const isAuthNeeded = (pathname: string) => {
  if (pathname === '/r') {
    return true
  }

  if (pathname === '/account/unlinked') {
    return true
  }

  if (pathname === '/account/unverified') {
    return true
  }

  if (pathname === '/account/verified') {
    return true
  }

  return ['/es', '/ts', '/debug'].some(page => pathname.startsWith(page))
}

const withAuthScope = (Component: ComponentType) => {
  const WithAuthScope = (props: any) => {
    const router = useRouter()
    const {isAuthenticated, isLoading, signIn} = useAuth()
    const hasLoaded = router.isReady && !isLoading
    const [state, setState] = React.useState<
      'LOADING' | 'AUTHENTICATED' | 'NOT_AUTHENTICATED' | 'ANONYMOUS' | 'LOGIN' | 'LOGIN_IS_LOADING'
    >('LOADING')

    React.useEffect(() => {
      switch (state) {
        case 'LOADING':
          if (hasLoaded) {
            setState(isAuthenticated ? 'AUTHENTICATED' : 'NOT_AUTHENTICATED')
          }
          return
        case 'AUTHENTICATED':
          if (!isAuthenticated) {
            setState('NOT_AUTHENTICATED')
          }
          return
        case 'NOT_AUTHENTICATED':
          if (isAuthNeeded(router.pathname)) {
            setState('LOGIN')
          } else {
            setState('ANONYMOUS')
          }
          return
        case 'LOGIN':
          {
            if (process.env.IS_MOBILE_BUILD) {
              if (router.pathname !== '/') {
                router.replace(`/?redirect=${router.asPath}`)
              }
            } else {
              const forceMt = router.query.forceMt as string
              const forceMfu = router.query.forceMfu as string
              const forceMf = router.query.forceMf as string

              const callbackUrl = new URL(`${auth0Params.redirect_uri}/authcallback`)
              callbackUrl.searchParams.set('redirect', router.asPath)

              if (forceMt) {
                callbackUrl.searchParams.set('forceMt', forceMt)
              }
              if (forceMfu) {
                callbackUrl.searchParams.set('forceMfu', forceMfu)
              }
              if (forceMf) {
                callbackUrl.searchParams.set('forceMf', forceMf)
              }

              signIn({
                callbackUrl: callbackUrl.toString(),
              })
            }

            setState('LOGIN_IS_LOADING')
          }
          return
        case 'ANONYMOUS':
        case 'LOGIN_IS_LOADING':
          if (isAuthenticated) {
            setState('AUTHENTICATED')
          }
          return
      }
    }, [hasLoaded, isAuthenticated, router, signIn, state])

    if (process.env.IS_MOBILE_BUILD) {
      if (state === 'LOGIN_IS_LOADING' && router.pathname === '/') {
        return <Component {...props} />
      }
    }

    if (isIn(['LOADING', 'NOT_AUTHENTICATED', 'LOGIN', 'LOGIN_IS_LOADING'], state)) {
      return <LoadingPage message="Authentification..." />
    }

    // ANONYMOUS || AUTHENTICATED
    return <Component {...props} />
  }

  hoistNonReactStatics(WithAuthScope, Component)
  return WithAuthScope
}

const withProductScope = (Component: ComponentType) => {
  function WithProductScope(props: any) {
    const profile = useProfile()
    const router = useRouter()

    const hasAccess = (() => {
      if (router.pathname.startsWith('/es/')) {
        return profile.mfus.length > 0
      }

      if (router.pathname.startsWith('/ts/')) {
        return profile.mts.filter(mtProfile => mtProfile.mt.type !== 'DISPATCHER').length > 0
      }

      return true
    })()

    React.useEffect(() => {
      if (!process.env.IS_MOBILE_BUILD) {
        return
      }

      if (!hasAccess) {
        router.replace(`/?redirect=${router.asPath}`)
      }
    }, [hasAccess, router])

    if (!hasAccess) {
      if (process.env.IS_MOBILE_BUILD) {
        return <LoadingPage message="Connectez-vous" />
      }

      if (router.pathname.startsWith('/es/')) {
        return <NotAuthorizedPage debug="Vous n'êtes pas rattaché à un établissement" />
      }

      if (router.pathname.startsWith('/ts/')) {
        return <NotAuthorizedPage debug="Vous n'êtes pas rattaché à un transporteur" />
      }

      return <NotAuthorizedPage debug="???" />
    }

    return <Component {...props} />
  }
  hoistNonReactStatics(WithProductScope, Component)
  return WithProductScope
}

export const withAuthStack = (Page: Parameters<typeof withNextApolloClient>[0]) =>
  compose(
    withNextApolloClient,
    withAuth0Provider,
    withAuthProvider,
    withAuthScope,
    withMultiMf,
    withProfile,
    withSentryUser,
    withProductScope,
    withConnectAsOverlay,
    withFavicon,
  )(Page)
