import type {ComponentType} from 'react'
import React from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'
import {safeLocalStorage} from '../../lib/local-storage'

export const MultiMfContext = React.createContext({
  mfId: null,
  setMfId: null,
})

const A4H_MULTIMF_CURRENT_ID = 'a4h.dashboard.lastUsedMfId'

export const useMultiMf = () => React.useContext(MultiMfContext)

export const withMultiMf = (Component: ComponentType) => {
  function WithMultiMf(props: any) {
    const [mfId, setMfId] = React.useState(() => safeLocalStorage.getItem(A4H_MULTIMF_CURRENT_ID))

    React.useEffect(() => {
      safeLocalStorage.setItem(A4H_MULTIMF_CURRENT_ID, mfId)
    }, [mfId])

    const value = React.useMemo(
      () => ({
        mfId,
        setMfId,
      }),
      [mfId],
    )

    return (
      <MultiMfContext.Provider value={value}>
        <Component {...props} />
      </MultiMfContext.Provider>
    )
  }
  hoistNonReactStatics(WithMultiMf, Component)
  return WithMultiMf
}
