import type {ProfileAsSelector} from '@ambler/shared'
import {isIn} from '@ambler/shared'
import {useQuery} from '@apollo/client'
import type {ComponentType} from 'react'
import React from 'react'
import gql from 'graphql-tag'
import {useRouter} from 'next/router'
import hoistNonReactStatics from 'hoist-non-react-statics'
import {uniqBy} from 'lodash'
import {useSessionStorage} from '../../hooks/use-storage'
import Sentry from '../../lib/sentry'
import {LoadingPage, useAuth} from './auth'
import {useMultiMf} from './multi-mf'
import type {APP_profileQuery_, APP_profileQueryVariables} from './profile.generated'

/**
 * Profile
 */

export type AppProfileFragment = APP_profileQuery_['profile'] & {refetchProfile: () => Promise<void>}

export function listAllMfs(profile: AppProfileFragment) {
  return uniqBy(
    profile.mfus.map(mfuProfile => mfuProfile.mfu.mf),
    mf => mf.id,
  )
}

const undefinedProfile: AppProfileFragment = {
  id: null,
  email: null,
  type: null,
  mfus: [],
  mts: [],
  refetchProfile: null,
}

const ProfileContext = React.createContext<AppProfileFragment>(undefinedProfile)

const profileQuery = gql`
  query APP_profileQuery($data: ProfileDataInput!) {
    profile(data: $data) {
      id
      email
      type
      mfus {
        id
        # Apollo caches objects without an "id" field with the object above.
        acl {
          canRead
          canWrite
          canSignFor
        }
        derived {
          hasSih
          preferredMfd {
            id
            type
            floor
            name
          }
          place {
            id
            mainText
            secondaryText
            address {
              id
              geopoint
            }
          }
          originFavorites {
            id
            mainText
            secondaryText
            address {
              id
              geopoint
            }
          }
          destinationFavorites {
            id
            mainText
            secondaryText
            address {
              id
              geopoint
            }
          }
        }
        mfu {
          id
          name
          hospitalType

          needAmbulance
          needVsl
          needTpmr
          needBariatric
          needParamedical
          needMedical

          needLdd
          needAutoOrder
          needPooling
          lddTransportCapacity
          needDelayedDispatch
          needSplitMissions
          needPayerDisplay
          needExports
          needIncidents
          needPatientReminder
          needPriorityDispatch

          # notifications
          needValidateGovFlatRates
          needOrderButton
          needPmtSignature
          needCerfaImport
          needRegulation
          needCancelPastOrder

          mf {
            id
            name
            timezone
            visitIdPattern

            mfds {
              id
              type
              floor
              name
            }
          }

          mfds {
            id
            type
            floor
            name
          }

          specificNeedsCustomizations {
            id
            vehicleType
            fields {
              id
              state
              label
            }
          }

          patientConditionsCustomizations {
            id
            vehicleType
            fields {
              id
              state
              label
            }
          }

          transportReasonCustomizationGroups {
            id
            index
            label
            transportReasonCustomizations {
              id
              label
              index
              reason
            }
          }

          customization {
            id

            # Patient step
            patientNirState
            patientNirLabel
            patientIppState
            patientIppLabel
            patientBirthdateState
            patientBirthdateLabel
            patientPhoneState
            patientPhoneLabel
            patientRoomState
            patientRoomLabel
            patientVisitIdState
            patientVisitIdLabel
            patientAddressState
            patientAddressLabel
            patientAddressDetailsState
            patientAddressDetailsLabel

            # Vehicle step
            ambulanceLabel
            vslLabel
            tpmrLabel
            bariatricLabel
            paramedicalLabel
            medicalLabel

            # Mission step
            missionMfuNotesState
            missionMfuNotesLabel
            missionDoctorState
            missionDoctorLabel
            missionChoiceForPayersLabel
            missionPreferredMtRules {
              id
              allowedArrivalMargin
              allowedDepartureMargin
              payers {
                id
                payer
              }
            }
          }
        }
      }
      mts {
        id

        # Apollo caches objects without an "id" field with the object above.
        acl {
          canRead
          canWrite
        }

        mt {
          id
          name
          type
        }
      }
    }
  }
`

const ProfileProvider: React.FC = ({children}) => {
  const router = useRouter()
  const {userId, isAuthenticated, isLoading} = useAuth()

  const urlConnectAs: ProfileAsSelector = (() => {
    if (router.query.forceMf) {
      return {
        type: 'MF',
        id: router.query.forceMf as string,
      }
    }

    if (router.query.forceMfu) {
      return {
        type: 'MFU',
        id: router.query.forceMfu as string,
      }
    }

    if (router.query.forceMt) {
      return {
        type: 'MT',
        id: router.query.forceMt as string,
      }
    }

    return undefined
  })()

  const [connectAs, setConnectAs, removeConnectAs] = useSessionStorage<ProfileAsSelector>('user-profile-as-selector')

  const variables = React.useMemo(
    () => ({
      data: {
        userId,
        ...(connectAs ? {as: connectAs} : undefined),
        ...(urlConnectAs ? {as: urlConnectAs} : undefined),
      },
    }),
    [connectAs, urlConnectAs, userId],
  )

  const handleCompleted = React.useCallback(
    (data: APP_profileQuery_) => {
      if (variables.data.as && data.profile.type === 'CONNECTED_AS') {
        setConnectAs(variables.data.as)
      } else {
        removeConnectAs()
      }
    },
    [removeConnectAs, setConnectAs, variables.data.as],
  )

  const query = useQuery<APP_profileQuery_, APP_profileQueryVariables>(profileQuery, {
    variables,
    onCompleted: handleCompleted,
    skip: isLoading || !isAuthenticated,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  })

  const [state, setState] = React.useState<'LOAD_PROFILE' | 'INVITED' | 'USER' | 'UNLINKED' | 'ANONYMOUS'>(
    'LOAD_PROFILE',
  )

  React.useEffect(() => {
    const profile = query.data?.profile
    const isProfileReady = Boolean(!query.loading && query.data?.profile)

    switch (state) {
      case 'LOAD_PROFILE':
        if (isLoading) {
          return
        }

        if (!isAuthenticated) {
          setState('ANONYMOUS')
          return
        }

        if (!isProfileReady) {
          return
        }

        if (profile.type === 'INVITED') {
          setState('INVITED')
          return
        }

        if (profile.mfus.length === 0 && profile.mts.length === 0) {
          setState('UNLINKED')
          return
        }

        setState('USER')
        return
      case 'INVITED':
        if (router.pathname === '/logout') {
          return
        }

        if (['/account/unverified', '/account/verified'].includes(router.pathname)) {
          if (profile.type !== 'INVITED') {
            setState('USER')
          }
          return
        }

        router.replace('/account/unverified')
        return
      case 'UNLINKED':
        if (router.pathname === '/logout') {
          return
        }

        if ('/account/unlinked' === router.pathname) {
          if (profile.mfus.length > 0 || profile.mts.length > 0) {
            setState('USER')
          }
          return
        }

        router.replace('/account/unlinked')
        return
      case 'ANONYMOUS':
        if (isAuthenticated) {
          setState('LOAD_PROFILE')
        }
        return
      case 'USER':
        if (!isAuthenticated) {
          setState('LOAD_PROFILE')
          return
        }

        if (profile.type === 'INVITED') {
          setState('INVITED')
          return
        }

        if (profile.mfus.length === 0 && profile.mts.length === 0) {
          setState('UNLINKED')
          return
        }
        return
    }
  }, [isLoading, isAuthenticated, query.loading, query.data?.profile, router, state])

  const profile = React.useMemo(() => {
    if (!isIn(['USER', 'INVITED', 'UNLINKED'], state)) {
      return undefinedProfile
    }

    const value = query.data?.profile

    return {
      ...value,
      refetchProfile: async () => {
        await query.refetch(variables)
      },
    }
  }, [query, state, variables])

  if (state === 'USER' && !query.data?.profile) {
    return <LoadingPage message="Chargement du profil..." />
  }

  if (state === 'ANONYMOUS') {
    return <>{children}</>
  }

  if (state === 'LOAD_PROFILE') {
    return <LoadingPage message="Chargement du profil..." />
  }

  if (state === 'INVITED' && !['/account/unverified', '/account/verified', '/logout'].includes(router.pathname)) {
    return <LoadingPage message="Redirection en cours..." />
  }

  if (state === 'UNLINKED' && !['/account/unlinked', '/logout'].includes(router.pathname)) {
    return <LoadingPage message="Redirection en cours..." />
  }

  return <ProfileContext.Provider value={profile}>{children}</ProfileContext.Provider>
}

export const withProfile = (Component: ComponentType) => {
  function WithProfile(props: any) {
    return (
      <ProfileProvider>
        <Component {...props} />
      </ProfileProvider>
    )
  }
  hoistNonReactStatics(WithProfile, Component)
  return WithProfile
}

export const useProfile = () => {
  const profile = React.useContext(ProfileContext)
  if (!profile) {
    return undefinedProfile
  }
  return profile
}

export const useMfProfile = () => {
  const profile = useProfile()
  const {mfId} = useMultiMf()

  // * on first render, either mfId is initialized from localStorage with a value,
  // * or the user has never selected which mf to focus (or is mono-mf) and therefore
  // * mfId is null.
  const firstMfuProfile = profile.mfus.find(mfuProfile => mfuProfile.mfu.mf.id === mfId) ?? profile.mfus[0]

  const mf = firstMfuProfile.mfu.mf
  const mfus = profile.mfus.filter(mfuProfile => mfuProfile.mfu.mf.id === mf.id)

  type BooleanKeys<T> = {
    [K in keyof T]: T[K] extends boolean ? K : never
  }[keyof T]

  return {
    mf,
    mfus,
    selectors: {
      findMfuWithFlag(flag: BooleanKeys<APP_profileQuery_['profile']['mfus'][number]['mfu']>) {
        return mfus.find(mfuProfile => mfuProfile.mfu[flag])
      },
      someMfuHasFlag(flag: BooleanKeys<APP_profileQuery_['profile']['mfus'][number]['mfu']>) {
        return mfus.some(mfuProfile => mfuProfile.mfu[flag])
      },
      filterMfusWithFlag(flag: BooleanKeys<APP_profileQuery_['profile']['mfus'][number]['mfu']>) {
        return mfus.filter(mfuProfile => mfuProfile.mfu[flag])
      },
    },
  }
}

export const useMfuProfile = (mfuId: string): AppProfileFragment['mfus'][number] => {
  const profile = useProfile()

  if (!mfuId) {
    Sentry.captureException(new Error('useMfuProfile is missing a mfuId. Missing mfuId prop'))
    return null
  }

  const mfuProfile = profile.mfus.find(mfu => mfu.id === mfuId)

  if (!mfuProfile) {
    Sentry.captureException(new Error('mfu not found in user profile'))
    return null
  }

  return mfuProfile
}
