import React from 'react'
import {filter} from 'graphql-anywhere'
import hoistNonReactStatics from 'hoist-non-react-statics'

export function withFragmentFilter<T>(fragments: T) {
  return function _<U extends Record<string, unknown>>(ComponentWithFragment: (props: U) => React.ReactNode) {
    function WithFragmentFilter(props: U) {
      const usedFragments = typeof fragments === 'function' ? fragments(props) : fragments
      const filteredProps = React.useMemo(
        () =>
          Object.keys(props).reduce((newProps, propKey) => {
            newProps[propKey] =
              usedFragments[propKey] && props[propKey] ? filter(usedFragments[propKey], props[propKey]) : props[propKey]
            return newProps
          }, {} as Record<string, unknown>),
        [props, usedFragments],
      )

      // @ts-ignore FixType
      return <ComponentWithFragment {...filteredProps} />
    }

    hoistNonReactStatics(WithFragmentFilter, ComponentWithFragment as React.ComponentType<U>)
    WithFragmentFilter.fragments = fragments

    return WithFragmentFilter
  }
}
