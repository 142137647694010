import {Andiv, DocumentBodyPortal, Text} from '@ambler/andive-next'
import type {ComponentType} from 'react'
import styled from 'styled-components'
import hoistNonReactStatics from 'hoist-non-react-statics'
import {useProfile} from './profile'

const GlowingBorder = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  // ? ios notch handling
  margin-top: env(safe-area-inset-top);

  border: 8px solid rgba(229, 181, 135, 0.8);
  z-index: 99999;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  pointer-events: none;
`

const ConnectAsOverlay = ({asName}: {asName: string}) => (
  <GlowingBorder>
    <Andiv p="8px" borderRadius="4px 0 0 0" bg="rgba(229, 181, 135, 0.8)">
      <Text t="body2">Connecté en tant que :</Text>&nbsp;
      <Text t="body1">{asName}</Text>
    </Andiv>
  </GlowingBorder>
)

export const withConnectAsOverlay = (Component: ComponentType) => {
  function WithConnectAsOverlay(props: any) {
    const profile = useProfile()

    if (!profile.id) {
      return <Component {...props} />
    }

    return (
      <>
        <Component {...props} />
        {profile.type === 'CONNECTED_AS' && (
          <DocumentBodyPortal>
            <ConnectAsOverlay
              asName={(() => {
                if (profile.mts.length > 0) {
                  return profile.mts[0].mt.name
                }

                if (profile.mfus.length > 0) {
                  if (profile.mfus.length === 1) {
                    return profile.mfus[0].mfu.name
                  } else {
                    return profile.mfus[0].mfu.mf.name
                  }
                }

                return '...'
              })()}
            />
          </DocumentBodyPortal>
        )}
      </>
    )
  }
  hoistNonReactStatics(WithConnectAsOverlay, Component)
  return WithConnectAsOverlay
}
