import {ApolloClient, InMemoryCache} from '@apollo/client'
import {setContext} from '@apollo/client/link/context'
import {onError} from '@apollo/client/link/error'
import {createUploadLink} from 'apollo-upload-client'
import fetch from 'isomorphic-unfetch'
import Router from 'next/router'
import {Auth0Client} from '@auth0/auth0-spa-js'

import {auth0Params} from '../components/stack/auth'

import {ErrorCodeType} from '../constants/enum'
import Sentry, {generateTraceId} from './sentry'

const ssrMode = !process.browser

if (process.env.AMBLER_STAGE !== 'prod') {
  console.log('GRAPHQL_ENDPOINT', process.env.GRAPHQL_ENDPOINT)
}

// Polyfill fetch() on the server (used by apollo-client)
if (ssrMode) {
  global.fetch = fetch
}

export function createApolloClient(initialState) {
  const traceId = generateTraceId()

  let authLink
  // ? ssr is not for authenticated calls at the moment
  if (!ssrMode) {
    const auth0Client = new Auth0Client(auth0Params)
    authLink = setContext(async (_, {headers}) => {
      let token
      try {
        await auth0Client.getTokenSilently() // ? this will refresh the token if expired
        token = await auth0Client.getIdTokenClaims()
      } catch (error) {
        // ? in our case, 'login_required' happens when disconnected from another tab
        if (error.error !== 'login_required') {
          throw error
        }
        return {headers}
      }

      if (!token) {
        return {headers}
      }
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token.__raw}`,
        },
      }
    })
  }

  const uploadLink = new createUploadLink({
    uri: process.env.GRAPHQL_ENDPOINT, // Server URL (must be absolute)
    headers: {
      'x-ambler-client-trace-id': traceId,
      'x-ambler-client-location': !ssrMode ? window.location.href : 'ssr', // TODO: remove from CORS as well
    },
  })

  const errorLink = onError(({operation, graphQLErrors, networkError}) => {
    if (networkError) {
      console.error('networkError', networkError)
      return
    }

    let shouldDisconnect = false
    // ? Find any unknown error.
    if (graphQLErrors) {
      graphQLErrors.forEach(err => {
        const isKnownError =
          (err.extensions && err.extensions.code && Object.keys(ErrorCodeType).includes(err.extensions.code)) || false

        if (err?.extensions?.code === 'INTERNAL_SERVER_ERROR' && err.message === 'Not Authorised!') {
          shouldDisconnect = true
        }

        if (!isKnownError) {
          Sentry.withScope(scope => {
            scope.setTag('operation', operation.operationName)
            scope.setTag('client_trace_id', traceId)
            Sentry.captureException(new Error(`${operation.operationName}: ${err.message}`))
          })
        }
      })
    }
    if (shouldDisconnect) {
      console.error('[apollo-client] Disconnected because of an error', {graphQLErrors, networkError})
      if (Router.pathname !== '/logout') {
        Router.replace('/logout')
      }
    }
  })

  let link = ssrMode ? uploadLink : authLink.concat(uploadLink)

  return new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode, // Disables forceFetch on the server (so queries are only run once)
    link: !ssrMode ? errorLink.concat(link) : link,
    name: `${process.env.AMBLER_STAGE}_APP`,
    version: process.env.version,
    cache: new InMemoryCache().restore(initialState || {}),
  })
}
